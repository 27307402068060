
import * as constants from "../../Components/Constant/Constant"
import axios from 'axios';
export const fetchCompanyListData = async (setCompanyDataList, setLoading) => {
    try {
        setLoading(true);
        const { data: response } = await axios.post(constants.BASE_URL + "companydata");
        setCompanyDataList(response);

    } catch (error) {
        console.error(error.message);
    } finally {
        setLoading(false);
    }
};