import React, { createContext, useState, useEffect } from 'react';
import { fetchCompanyListData } from "../Utils/getCompanyListData"

export const CompanyContext = createContext();

export const CompanyProvider = ({ children }) => {
  const [companyList, setCompanyList] = useState([]);
  const [companyListLoading, setCompanyListLoading] = useState(true);

  // Fetch company lists
  useEffect(() => {
    fetchCompanyListData(setCompanyList, setCompanyListLoading)
  }, [])

  return (
    <CompanyContext.Provider value={{ companyList, companyListLoading }}>
      {children}
    </CompanyContext.Provider>
  );
};
